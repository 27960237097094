import _capitalize from "lodash/capitalize";

import _camelCase from "lodash/camelCase";
import { isEmpty } from "../helpers/common";

export const DOCUMENT_TITLE = "翡翠餐飲集團";
export const DOCUMENT_TITLE_ENG = "Crystal Jade HK";
export const APP_VERSION = "0.0.1";
export const APP_NAME = "app";
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
export const GG_MAP_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;
export const DEFAULT_GMT = "+08:00";
export const DEFAULT_PHONE_COUNTRY_CODE = "+852";
export const CAPTCHA_KEY = process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY;

export const generateClassName = (
  sheetName: string,
  ruleName: string,
  identifier?: string
) => {
  return `${_capitalize()}${_capitalize(_camelCase(sheetName))}-${_camelCase(
    ruleName
  )}${!isEmpty(identifier) ? `-${identifier}` : ""}`;
};
