const appColors = {
    blue: "#5072EE",
  
    purpleBg: '#8153CA',
    pinkDark: "#4B2884",
    pink: "#542C74",
    pinkLight: "#DBD4E6",
    pinkLighter: "#ECE1F4",
    pinkLightest: "#f5f0f9",
  
    neutralDark: "#383838",
    neutral: "#8F8F8F",
    neutralLight: "#EAE9E9",
    neutralLighter: "#F5F5F5",
    neutralLightest: "#FCFCFC",
  
    error: "#FF6261",
  };

export default appColors